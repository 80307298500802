/* eslint no-console:0 */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'whatwg-fetch'
import polyfill from 'cross-browser-polyfill'
import includes from 'array-includes'
import '../trix/config'
import '../trix/text_color_attributes'
import Rails from '@rails/ujs'
import scrollToAnchor from 'scroll-to-anchor'
import anchorModal from '../utils/anchor_modal'
import '../utils/fontawesome'
import "@hotwired/turbo-rails"
import * as bootstrap from 'bootstrap'
import $ from 'jquery'
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import { GetAddressIOController } from 'stimulus-getaddress-io'

bootstrap

window.Stimulus = Application.start()
const context = require.context("../controllers", true, /\.js$/)
const contextComponents = require.context("../../components", true, /_controller\.js$/)
Stimulus.load(definitionsFromContext(context).concat(definitionsFromContext(contextComponents)))
Stimulus.register('getaddress-io', GetAddressIOController)

Rails.start()

require('@rails/activestorage').start()

polyfill()

includes.shim()

document.addEventListener('turbo:load', () => scrollToAnchor(window.location.hash, {behavior: 'smooth'}))
document.addEventListener('turbo:load', () => anchorModal(window.location.hash))
document.addEventListener("turbo:load", () => {
  $('[data-toggle="popover"]').popover({ container: "body" })
})


require('trix')
require('@rails/actiontext')

// [React] Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
